/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { handleLoading } from '@app/common/rxjs-operators';
import { extractError } from '@app/common/utils';
import { interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { Clusters3xApiService } from '@app/core/services/clusters-3x.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  initializeCluster,
  initializeClusterErr,
  initializeClusterOK,
  loadIgnite3PhysicalTopologyErr,
} from './actions/cluster-3x.actions';

@Injectable()
export class Clusters3xEffects {
  constructor(
    private actions$: Actions,
    private clusters3xApi: Clusters3xApiService,
    private matDialog: MatDialog,
  ) {}

  loading$ = createEffect(() =>
    this.actions$.pipe(handleLoading([{ start: initializeCluster, end: [initializeClusterOK, initializeClusterErr] }])),
  );

  initializeClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeCluster),
      switchMap((action) =>
        this.clusters3xApi.initialize(action).pipe(
          map(() => initializeClusterOK({ notification: 'Cluster initialized' })),
          catchError((err) =>
            of(initializeClusterErr({ error: extractError(err, 'Failed to initialize the cluster') })),
          ),
        ),
      ),
    ),
  );

  closeInitializeDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeClusterOK),
        tap(() => this.matDialog.closeAll()),
      ),
    { dispatch: false },
  );

  notifyAboutTopologyLoadError$ = createEffect(() =>
    interceptActionTaskErrors(this.clusters3xApi, 'getPhysicalTopology').pipe(
      map((err) =>
        loadIgnite3PhysicalTopologyErr({
          error: extractError(err, 'Failed to load cluster physical topology'),
        }),
      ),
    ),
  );
}
