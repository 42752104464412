/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const iconsAppInitializer = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (icons: MatIconRegistry, sanitizer: DomSanitizer) => () => {
    icons.addSvgIconInNamespace(
      'ggcc',
      'java',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-java.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'cpp',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-cpp.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'dotnet',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-dotnet.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'python',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-python.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'javascript',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-javascript.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'ignite',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ignite-logo.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'gridgain',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/gridgain.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'progress',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/progress.icon.svg'),
    );

    icons.addSvgIconInNamespace(
      'ggcc',
      'database',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-database.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'project-diagram',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-project-diagram.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'coins',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-coins.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'column',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-column.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'no-agents',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-no-agents.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'destroying-circle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-destroying-rounded.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'more-circle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-more-rounded.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'edit-circle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-edit-rounded.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'arrow-down',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-arrow-down.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'speed-icon',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-speed.icon.svg'),
    );
    // Collection of icons from material/fontawesome with modified viewport, to remove gaps at the bottom and make them the same view size
    icons.addSvgIconInNamespace(
      'ggcc',
      'admin-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-admin.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'alerting-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-alerting.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'caches-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-caches.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'clusters-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-clusters.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'dashboard-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-dashboard.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'error-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-error.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'queries-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-queries.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'search-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-search.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'snapshot-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-snapshot.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'deployment-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-deployment.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'teams-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-teams.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'tracing-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-tracing.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'summarize-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-summarize.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'billing-wallet-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-billing-wallet.icon.svg'),
    );

    // Billing card logos
    icons.addSvgIconInNamespace(
      'ggcc',
      'amex-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/AmericanExpress.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'diners-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/DinersClub.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'discover-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/Discover.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'jcb-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/JCB.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'mastercard-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/MasterCard.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'unionpay-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/UnionPay.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'unknown-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/UnknownPaymentMethod.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'visa-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/billing-card-logos/Visa.svg'),
    );

    // Create cluster logos
    icons.addSvgIconInNamespace(
      'ggcc',
      'create-nebula',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-create-nebula.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'create-ignite',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-create-ignite.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'create-gridgain',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-create-gridgain.icon.svg'),
    );

    // Nebula Providers logos
    icons.addSvgIconInNamespace(
      'ggcc',
      'nebula-aws-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/nebulaProviders/aws.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'nebula-azure-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/nebulaProviders/azure.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'nebula-cloud-logo',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/nebulaProviders/cloud.svg'),
    );

    icons.addSvgIconInNamespace(
      'material-filled',
      'settings',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-settings.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-filled',
      'save',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-save.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-outlined',
      'pin',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-outlined-pin.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-outlined',
      'filter_list_off',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-outline-filter-list-off.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-outlined',
      'start',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-outlined-start.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-outlined',
      'terminal',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-outlined-terminal.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-filled',
      'widgets',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-widgets.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-filled',
      'arrow_circle_left',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-arrow-circle-left.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-filled',
      'task-alt',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-task-alt.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-filled',
      'stop-circle',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-filled-stop-circle.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'download',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-download.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'material-symbols-outlined',
      'flowsheet',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/material-symbols-outlined-flowsheet.icon.svg'),
    );
    icons.addSvgIconInNamespace(
      'ggcc',
      'hourglass-circled',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/images/ggcc-hourglass-circled.icon.svg'),
    );
  },
  deps: [MatIconRegistry, DomSanitizer],
};

@NgModule({ providers: [iconsAppInitializer, provideHttpClient(withInterceptorsFromDi())] })
export class GmcIconsTestingModule {}
