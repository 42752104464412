<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<h1 mat-dialog-title i18n="@@dialogSecureClusterSigninTitle">Secure cluster sign in</h1>
<form
  mat-dialog-content
  [formGroup]="form"
  id="sign-in-form"
  scrollToFirstInvalid
  (ngSubmit)="submit()"
  gta="add_cluster_modal"
>
  <form-cluster-signin
    [formGroup]="$any(form.controls.cluster)"
    [lockConnectionTokenTo]="data.connectionToken"
    [showConnectionTokenInput]="data.showConnectionTokenInput"
    [showDeferToProviderToggle]="true"
  ></form-cluster-signin>
</form>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" gta="dismiss_btn" i18n="@@dialogSecureClusterSigninDismissButton"
    >Cancel</button
  >
  <button type="submit" mat-button form="sign-in-form" gta="confirm_btn" i18n="@@dialogSecureClusterSigninSubmitButton"
    >Sign in</button
  >
</div>
