/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { APP_INITIALIZER, Provider } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClusterActionService } from '@app/core/services';
import {
  DialogSecureClusterSignInComponent,
  DialogSecureClusterSignInData,
  DialogSecureClusterSignInReturnValue,
} from '@common/components/dialog-secure-cluster-sign-in/dialog-secure-cluster-sign-in.component';
import { DIALOG_WIDTH_SM } from '@common/constants/dialog-width';
import { simpleHook } from '@common/utils/simple-hook';
import { SECURE_CLUSTER_SESSION_NOT_FOUND } from '@shared/constants/api-error-codes';
import { ClusterActionResponseError } from '@shared/types/cluster-actions';
import { catchError, delay, switchMap, throwError } from 'rxjs';
import { secondsToMilliseconds } from './domain/time';

const RETRY_DELAY = secondsToMilliseconds(1);

export const secureClusterSessionAppInitializer: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (actions: ClusterActionService, matDialog: MatDialog) => () => {
    simpleHook(actions, 'dispatch', (response, args) =>
      response.pipe(
        catchError((err: ClusterActionResponseError[]) => {
          if (err.some((e) => e.code === SECURE_CLUSTER_SESSION_NOT_FOUND)) {
            const openedDialog = matDialog.openDialogs.find(
              (dialog) => dialog.componentInstance instanceof DialogSecureClusterSignInComponent,
            );
            if (openedDialog) {
              return openedDialog.afterClosed().pipe(
                delay(RETRY_DELAY),
                switchMap((credentials) => (!credentials ? throwError(() => err) : actions.dispatch(...args))),
              );
            } else {
              return matDialog
                .open<
                  DialogSecureClusterSignInComponent,
                  DialogSecureClusterSignInData,
                  DialogSecureClusterSignInReturnValue
                >(DialogSecureClusterSignInComponent, {
                  data: { connectionToken: 'foo', showConnectionTokenInput: false },
                  width: DIALOG_WIDTH_SM,
                })
                .afterClosed()
                .pipe(
                  switchMap((credentials) =>
                    !credentials
                      ? throwError(() => err)
                      : actions.dispatch(args[0], args[1], { ...(args[2] || {}), credentials }),
                  ),
                );
            }
          } else {
            return throwError(() => err);
          }
        }),
      ),
    );
  },
  deps: [ClusterActionService, MatDialog],
};
