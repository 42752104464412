/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { handleLoading } from '@app/common/rxjs-operators/handle-loading';
import { extractError } from '@app/common/utils';
import { withClusterId } from '@app/common/utils/with-cluster-id';
import { AppState } from '@app/core/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { BaselineApi } from '../services/baseline-api.service';
import {
  changeClusterBaselineAutoAdjust,
  changeClusterBaselineAutoAdjustErr,
  changeClusterBaselineAutoAdjustOk,
  changeClusterBaselineAutoAdjustTimeout,
  changeClusterBaselineAutoAdjustTimeoutErr,
  changeClusterBaselineAutoAdjustTimeoutOk,
  changeCurrentClusterBaselineAutoAdjust,
  changeCurrentClusterBaselineAutoAdjustTimeout,
  setClusterBaselineNodes,
  setClusterBaselineNodesErr,
  setClusterBaselineNodesOk,
  setCurrentClusterBaselineNodes,
} from './baseline.actions';

@Injectable()
export class BaselineEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private baselineApi: BaselineApi,
  ) {}

  initLoading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        {
          start: changeClusterBaselineAutoAdjust,
          end: [changeClusterBaselineAutoAdjustOk, changeClusterBaselineAutoAdjustErr],
        },
        {
          start: changeClusterBaselineAutoAdjustTimeout,
          end: [changeClusterBaselineAutoAdjustTimeoutOk, changeClusterBaselineAutoAdjustTimeoutErr],
        },
        { start: setClusterBaselineNodes, end: [setClusterBaselineNodesOk, setClusterBaselineNodesErr] },
      ]),
    ),
  );

  // Set auto adjust enabled
  changeCurrentClusterBaselineAutoAdjust$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeCurrentClusterBaselineAutoAdjust),
      withClusterId(this.store, (a, clusterId) => changeClusterBaselineAutoAdjust({ ...a, clusterId })),
    ),
  );
  changeClusterBaselineAutoAdjust$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeClusterBaselineAutoAdjust),
      mergeMap((a) =>
        this.baselineApi.setClusterBaselineAutoAdjust(a.clusterId, a.baselineAutoAdjustEnabled).pipe(
          switchMap(() =>
            of(
              changeClusterBaselineAutoAdjustOk({
                ...a,
                notification: $localize`:Cluster baseline topology auto adjust change success snack bar notification@@baselineTopologyAutoAdjustChangeSuccessNotification:Cluster baseline auto adjust ${
                  a.baselineAutoAdjustEnabled ? 'enabled' : 'disabled'
                }`,
              }),
            ),
          ),
          catchError((err) => of(changeClusterBaselineAutoAdjustErr({ error: extractError(err), ...a }))),
        ),
      ),
    ),
  );

  // Change baseline auto adjust timeout
  changeCurrentClusterBaselineAutoAdjustTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeCurrentClusterBaselineAutoAdjustTimeout),
      withClusterId(this.store, (a, clusterId) => changeClusterBaselineAutoAdjustTimeout({ ...a, clusterId })),
    ),
  );
  changeClusterBaselineAutoAdjustTimeout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeClusterBaselineAutoAdjustTimeout),
      mergeMap((a) =>
        this.baselineApi.setClusterAutoAdjustAwaitingTime(a.clusterId, a.baselineAutoAdjustTimeout).pipe(
          switchMap(() =>
            of(
              changeClusterBaselineAutoAdjustTimeoutOk({
                ...a,
                notification: $localize`:Cluster baseline topology auto adjust timeout success snack bar notification@@baselineTopologyAutoAdjustTimeoutChangeSuccessNotification:Cluster baseline auto adjust timeout set to ${
                  a.baselineAutoAdjustTimeout / 1000
                } s`,
              }),
            ),
          ),
          catchError((err) => of(changeClusterBaselineAutoAdjustTimeoutErr({ error: extractError(err), ...a }))),
        ),
      ),
    ),
  );

  // Set baseline nodes
  setCurrentClusterBaselineNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentClusterBaselineNodes),
      withClusterId(this.store, (a, clusterId) => setClusterBaselineNodes({ ...a, clusterId })),
    ),
  );
  setClusterBaselineNodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setClusterBaselineNodes),
      mergeMap((a) =>
        this.baselineApi.setClusterBaselineNodes(a.clusterId, a.baselineNodeConsistentIds).pipe(
          switchMap(() =>
            of(
              setClusterBaselineNodesOk({
                ...a,
                notification: $localize`:Cluster baseline topology nodes change success snack bar notification@@baselineTopologyNodesChangeSuccessNotification:Cluster baseline changed`,
              }),
            ),
          ),
          catchError((err) => of(setClusterBaselineNodesErr({ error: extractError(err), ...a }))),
        ),
      ),
    ),
  );
}
