<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

@if (isLoading$ | async) {
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
}
<div #header>
  <app-header [showMenuButton]="(hideSidebar$ | async) !== true" (menuButtonClick)="sidenav.toggle()"></app-header>
  <banner></banner>
</div>

<mat-sidenav-container>
  @if ((hideSidebar$ | async) !== true) {
    <mat-sidenav
      #matSidenav
      [mode]="screenWidth > 1120 ? 'side' : 'over'"
      fixedInViewport
      [fixedTopGap]="headerHeight"
      autosize
      [ngClass]="{ wide: matSidenav.opened }"
      gta="sidebar"
    >
      <div class="sidenav-content-wrapper">
        <app-side-navigation
          [navigationItems]="(navigationItems$ | async)!"
          [wideMode]="matSidenav.opened"
        ></app-side-navigation>
        <app-sidebar-footer [wideMode]="matSidenav.opened"></app-sidebar-footer>
      </div>
    </mat-sidenav>
  }
  <mat-sidenav-content [ngClass]="{ 'sidebar-hidden': hideSidebar$ | async }">
    <div
      [ngClass]="{
        'page-wrapper--public': isOnInitPage$ | async
      }"
      class="page-wrapper"
    >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
