/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { isFormGroupInvalid } from '@app/common/utils/is-form-group-invalid';
import { ConnectionToken } from '@app/core/types';
import { ClusterCredentials } from '@shared/types/cluster';
import { ScrollToFirstInvalidDirective } from '../../directives/scroll-to-first-invalid.directive';
import { FormClusterSigninComponent } from './form-cluster-signin/form-cluster-signin.component';

export interface DialogSecureClusterSignInData {
  connectionToken?: ConnectionToken;
  showConnectionTokenInput?: boolean;
}

export type DialogSecureClusterSignInReturnValue = ClusterCredentials;

@Component({
  templateUrl: './dialog-secure-cluster-sign-in.component.html',
  styleUrls: ['./dialog-secure-cluster-sign-in.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    ScrollToFirstInvalidDirective,
    FormClusterSigninComponent,
    MatButtonModule,
  ],
})
export class DialogSecureClusterSignInComponent implements OnInit {
  constructor(
    private dialog: MatDialogRef<DialogSecureClusterSignInComponent, DialogSecureClusterSignInReturnValue>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSecureClusterSignInData,
  ) {}
  form = new FormGroup({ cluster: new FormGroup<any>({}) });
  ngOnInit() {
    this.form.controls.cluster.patchValue({ connectionToken: this.data.connectionToken });
  }
  submit() {
    this.form.markAsTouched();
    if (isFormGroupInvalid(this.form)) return;
    this.dialog.close({
      login: this.form.value.cluster.username,
      password: this.form.value.cluster.password,
      useAccessToken: this.form.value.cluster.useAccessToken,
    });
  }
}
