<!--
   Copyright (C) GridGain Systems. All Rights Reserved.
   _________        _____ __________________        _____
   __  ____/___________(_)______  /__  ____/______ ____(_)_______
   _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
   / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
   \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
-->

<!-- Forces icons to always white -->
<mat-toolbar class="dark-theme">
  @if (showMenuButton) {
    <div class="menu-button-wrapper">
      <button
        type="button"
        gta="toggle-sidebar-button"
        mat-icon-button
        matTooltip="Expand/collapse navigation"
        (click)="menuButtonClick.emit()"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  }
  <ng-container *showForTarget="'hosted'">
    @if (isAuthorized$ | async) {
      <a class="header-logo nebula" gta="gg-logo" routerLink="">
        <img src="assets/images/ggcc-nebula-logo-dark.svg" />
      </a>
    } @else {
      <a class="header-logo nebula" gta="gg-logo" href="https://www.gridgain.com/products/gridgain-nebula">
        <img src="assets/images/ggcc-nebula-logo-dark.svg" />
      </a>
    }
  </ng-container>
  <ng-container *showForTarget="'on-premise'">
    @if (isAuthorized$ | async) {
      <a class="header-logo" gta="gg-logo" routerLink="">
        <img src="assets/images/ggcc-logo-dark.svg" />
      </a>
    } @else {
      <a class="header-logo" gta="gg-logo" href="https://www.gridgain.com/products/control-center">
        <img src="assets/images/ggcc-logo-dark.svg" />
      </a>
    }
  </ng-container>

  @if (showTrialWarning$ | async) {
    <span class="trial-warning">
      @if (isAdmin$ | async) {
        <a routerLink="admin/license">Trial: not for production usage</a>
      } @else {
        <span class="simpleTrialText">Trial: not for production usage</span>
      }
    </span>
  }
  <span class="spacer"></span>

  <ng-template #contactUsHosted>
    <a
      class="contact-us-link"
      [routerLink]="['.', { outlets: { dialog: ['contact-form'] } }]"
      gta="header__contact-us-link-hosted"
      >Support</a
    >
  </ng-template>

  @if (isAuthorized$ | async) {
    <ng-container *showForTarget="'hosted'">
      <ng-container *ngTemplateOutlet="contactUsHosted"></ng-container>
      @if (hasClusters$ | async) {
        <mat-divider class="divider" vertical></mat-divider>
      }
    </ng-container>
    <current-cluster-select (click)="dismissNotifications()"></current-cluster-select>
    <mat-divider class="divider" vertical></mat-divider>
    <button-add-cluster></button-add-cluster>
    <ng-container *showForTarget="'hosted'">
      @if (isAuthorized$ | async) {
        <mat-divider class="divider" vertical></mat-divider>
        <notification-button gta="header__notifications-button"></notification-button>
      }
    </ng-container>
    <mat-divider class="divider" vertical> </mat-divider>
    <button
      type="button"
      mat-icon-button
      [matMenuTriggerFor]="userMenu"
      (click)="dismissNotifications()"
      class="avatar-wrapper"
      gta="user-menu-trigger-button"
    >
      <avatar [email]="(email$ | async)!"></avatar>
    </button>
    <mat-menu #userMenu="matMenu" gta="profile_dropdown">
      @if ((user$ | async)!; as user) {
        <div mat-menu-item disabled disableRipple class="userInfo" gta="user_info">
          <div class="userInfo__content">
            <b gta="user-menu-full-name">{{ userFullNameOrEmail(user) }}</b>
            @if (user.firstName || user.lastName) {
              <span class="user-email" gta="user-menu-email">{{ (user$ | async)?.email }}</span>
            }
          </div>
        </div>
      }

      <mat-divider></mat-divider>

      <button type="button" mat-menu-item (click)="toggleMode(); $event.stopPropagation()" gta="dark-mode-toggle">
        <mat-icon>{{ (theme$ | async) === 'dark' ? 'dark_mode' : 'light_mode' }}</mat-icon>
        <span i18n="Theme change button label in header@@appHeaderThemeChangeBtn"
          >Dark mode: {(theme$|async), select, dark {On} other {Off}
        }</span>
      </button>
      @if (canOpenUserProfile$ | async) {
        <a mat-menu-item routerLink="profile" gta="profile_btn">
          <mat-icon>account_circle</mat-icon>
          <span i18n="Profile button label in header@@appHeaderProfileBtn">Profile</span>
        </a>
      }
      <ng-container *showForTarget="'hosted'">
        <a mat-menu-item [routerLink]="['billing', { plantype: 'nebula' }]" gta="billing_btn">
          <mat-icon>credit_card</mat-icon>
          <span i18n="Billing button label in header@@appHeaderBillingBtn">Billing</span>
        </a>
      </ng-container>
      <a mat-menu-item [routerLink]="['clusters', 'list']" gta="header__menu-item-cluster-management">
        <mat-icon svgIcon="ggcc:clusters-alt"></mat-icon>
        <span>Cluster management</span>
      </a>
      <a mat-menu-item routerLink="teams" gta="teams_btn">
        <mat-icon svgIcon="ggcc:teams-alt"></mat-icon>
        <span>Team management</span>
      </a>

      <mat-divider></mat-divider>

      <a mat-menu-item [href]="releaseNotes$ | async" gta="releaseNotes_btn" target="_blank" rel="noopener">
        <mat-icon>new_releases</mat-icon>
        <span i18n="WhatsNew button label in header@@appHeaderWhatsNewBtn">What's new</span>
      </a>

      <button mat-menu-item [matMenuTriggerFor]="helpMenu" gta="header__help-submenu-trigger">
        <mat-icon>help</mat-icon>
        <span i18n="Help button label in header@@appHeaderHelpBtn">Help</span>
      </button>

      <mat-divider></mat-divider>

      <button type="button" mat-menu-item (click)="logout()" gta="logout_btn">
        <mat-icon>logout</mat-icon>
        <span i18n="Log Out button label in header@@appHeaderLogOutBtn">Log out</span>
      </button>
    </mat-menu>
  } @else {
    <nav *showForTarget="'hosted'">
      <ng-container *ngTemplateOutlet="contactUsHosted"></ng-container>
    </nav>
    <nav *showForTarget="'on-premise'">
      <a
        class="signlink"
        href="https://www.gridgain.com/contact"
        target="_blank"
        rel="noopener"
        gta="header__contact-us-link-on-premise"
        >Support</a
      >
    </nav>
    @if (signupEnabled$ | async) {
      <a routerLink="auth/signup" mat-flat-button color="primary" gta="signup_btn">Sign up</a>
    }
    <a
      routerLink="auth/signin"
      mat-stroked-button
      color="primary"
      gta="signin_btn"
      i18n="Sign In button label in header@@appHeaderSignInBtn"
      >Sign in</a
    >
  }

  <mat-menu #helpMenu="matMenu">
    <a mat-menu-item [href]="documentationLink$ | async" target="_blank" gta="documentation_btn">
      <mat-icon>book</mat-icon>
      <span i18n="Documentation button label in header@@appHeaderDocumentationBtn">Documentation</span>
    </a>
    <a
      mat-menu-item
      href="https://www.gridgain.com/docs/tutorials/management-monitoring/overview"
      target="_blank"
      rel="noopener"
    >
      <mat-icon>school</mat-icon>
      <span i18n="Tutorials button label in header@@appHeaderTutorialsBtn">Tutorials</span>
    </a>
    <a mat-menu-item href="https://www.gridgain.com/resources/webinars" target="_blank" rel="noopener">
      <mat-icon>ondemand_video</mat-icon>
      <span i18n="Training button label in header@@appHeaderTrainingBtn">Training</span>
    </a>
    <ng-container *showForTarget="'on-premise'">
      <a
        mat-menu-item
        href="https://www.gridgain.com/services/overview"
        target="_blank"
        gta="header__user-action-support-on-premise"
      >
        <mat-icon>support</mat-icon>
        <span i18n="Support button label in header@@appHeaderSupportBtn">Support</span>
      </a>
    </ng-container>
  </mat-menu>
</mat-toolbar>
