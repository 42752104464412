/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { ClipboardAllowedMimeTypes } from '@app/core/types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import copy from 'copy-to-clipboard';
import { map, tap } from 'rxjs/operators';
import { copy as copyAction, copyingCompleted } from './actions/util.actions';

const DEFAULT_MIME_TYPE: ClipboardAllowedMimeTypes = 'text/plain';

@Injectable()
export class UtilEffects {
  constructor(private actions$: Actions) {}

  copy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(copyAction),
      tap(({ text, mimeType = DEFAULT_MIME_TYPE }) => copy(text, { format: mimeType })),
      map(() => copyingCompleted({ notification: $localize`:@@copyingCompletedNotification:Copied to clipboard` })),
    ),
  );
}
