/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
  createUrlTreeFromSnapshot,
} from '@angular/router';
import { mapUrlTreePrimarySegments, urlTreesEqual } from '@app/common/utils/router';
import { clusterManagementTeams, clustersLoaded, currentClusterInfo } from '@app/core/ngrx';
import { AppState } from '@app/core/types';
import { firstTeamClusterBelongsTo } from '@app/domain/teams';
import { Store } from '@ngrx/store';
import { ClusterInfo } from '@shared/types/cluster';
import { Team, TeamId } from '@shared/types/team';
import { isString } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ActiveTeamGuard {
  constructor(private store: Store<AppState>) {}

  currentCluster$ = this.store.select(clustersLoaded).pipe(
    filter(Boolean),
    switchMap(() => this.store.select(currentClusterInfo)),
  );
  teams$ = this.store.select(clusterManagementTeams).pipe(filter(Boolean));

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { currentTeam: routeTeamId } = route.params;

    const chooseTeam = (teams: Team[], cluster?: ClusterInfo): Team | undefined => {
      return cluster ? firstTeamClusterBelongsTo(teams, cluster) ?? undefined : teams[0];
    };

    const replaceTeam = (tree: UrlTree, teamId: TeamId | null) =>
      mapUrlTreePrimarySegments(tree, (segment) => {
        if (segment.path !== 'list') return segment;
        const { currentTeam, ...rest } = segment.parameters;
        return new UrlSegment(segment.path, isString(teamId) ? { ...rest, currentTeam: teamId } : rest);
      });

    return combineLatest([this.currentCluster$, this.teams$]).pipe(
      map(([cluster, teams]) => {
        const tree = createUrlTreeFromSnapshot(
          route,
          [],
          route.queryParams, // Don't forget about query params, otherwise connectionToken will disappear
        );
        if (teams.length > 1) {
          const routeTeam = teams.find(({ id }) => id === routeTeamId);
          const newTeam = chooseTeam(teams, cluster);
          if (routeTeam) return true;
          if (!newTeam) return false; // No team, should not happen
          const newTree = replaceTeam(tree, newTeam.id);
          return urlTreesEqual(tree, newTree) ? true : newTree; // Stucks in a loop if redirects to same URL as current one
        } else {
          const newTree = replaceTeam(tree, null);
          return routeTeamId && !urlTreesEqual(tree, newTree) ? replaceTeam(tree, null) : true; // Stucks in a loop if redirects to same URL as current one
        }
      }),
    );
  }
}
