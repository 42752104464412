/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import {
  clusterList,
  isAuthorized,
  isNonLdap,
  licenseStatus,
  logout,
  signupEnabled,
  targetSelector,
  theme,
  toggleTheme,
  user,
} from '@app/core/ngrx';
import { userIsAdmin } from '@app/core/ngrx/user.selectors';
import { dismissAllNotifications } from '@app/core/notification/ngrx';
import { BuildInfo } from '@app/core/services/build-info';
import { AppState } from '@app/core/types';
import { userFullNameOrEmail } from '@app/domain/user';
import { Store } from '@ngrx/store';
import { User } from '@shared/types/common';
import { GGCCLicenseType } from '@shared/types/license';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AvatarComponent } from '../../common/components/avatar/avatar.component';
import { ShowForTargetDirective } from '../../common/modules/ggcc-target/show-for-target.directive';
import { NotificationButtonComponent } from '../notification/notification-button/notification-button.component';
import { ButtonAddClusterComponent } from './button-add-cluster/button-add-cluster.component';
import { CurrentClusterSelectComponent } from './current-cluster-select/current-cluster-select.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    ShowForTargetDirective,
    RouterLink,
    NgTemplateOutlet,
    MatDividerModule,
    CurrentClusterSelectComponent,
    ButtonAddClusterComponent,
    NotificationButtonComponent,
    MatMenuModule,
    AvatarComponent,
    AsyncPipe,
  ],
})
export class HeaderComponent {
  constructor(
    private store: Store<AppState>,
    private buildInfo: BuildInfo,
  ) {}

  @Output()
  menuButtonClick = new EventEmitter();

  @Input()
  showMenuButton = false;

  theme$ = this.store.select(theme);

  isAuthorized$: Observable<boolean> = this.store.select(isAuthorized);
  user$ = this.store.select(user);
  isAdmin$ = this.store.select(userIsAdmin);
  signupEnabled$: Observable<boolean> = this.store.select(signupEnabled);
  canOpenUserProfile$: Observable<boolean> = this.store.select(isNonLdap);
  hasClusters$ = this.store.select(clusterList).pipe(map((a) => !!a && a.length));

  email$ = this.user$.pipe(
    filter((u): u is User => !!u),
    map((u) => u.email),
  );

  showTrialWarning$ = combineLatest([this.user$, this.store.select(licenseStatus)]).pipe(
    map(([user, status]) => user && status?.licenseType === GGCCLicenseType.developer),
  );

  userFullNameOrEmail = (user: User) => userFullNameOrEmail(user);

  releaseNotes$ = this.store.select(targetSelector).pipe(
    map((target) => {
      return target === 'hosted'
        ? `https://www.gridgain.com/docs/nebula/release-notes/index`
        : `https://www.gridgain.com/docs/control-center/latest/on-premise/release-notes/control-center/${this.buildInfo.productVersion}`;
    }),
  );
  documentationLink$ = this.store.select(targetSelector).pipe(
    map((target) => {
      return target === 'hosted'
        ? 'https://www.gridgain.com/docs/nebula/about/overview'
        : 'https://www.gridgain.com/docs/control-center/latest/overview';
    }),
  );

  toggleMode() {
    this.store.dispatch(toggleTheme());
  }

  logout() {
    this.store.dispatch(logout());
  }

  dismissNotifications() {
    this.store.dispatch(dismissAllNotifications());
  }
}
