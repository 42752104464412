/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { extractError } from '@app/common/utils';
import { endpointLoading, interceptActionTaskErrors } from '@app/common/utils/task-interceptor';
import { CacheApi } from '@app/core/services/cache.service';
import { AppState } from '@app/core/types';
import { runScanQuery } from '@app/sql/ngrx';
import { plural } from '@common/utils/plural';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ClusterActionResponseError } from '@shared/types/cluster-actions';
import { isEmpty } from 'lodash-es';
import { EMPTY, from, of } from 'rxjs';
import { catchError, map, mergeMap, pluck, switchMap, take, withLatestFrom } from 'rxjs/operators';
import {
  caches as cachesAction,
  clearCache,
  clearCacheErr,
  clearCacheOk,
  destroyCache,
  destroyCacheErr,
  destroyCacheOk,
  disableCacheStatistics,
  disableCacheStatisticsErr,
  disableCacheStatisticsOk,
  enableCacheStatistics,
  enableCacheStatisticsErr,
  enableCacheStatisticsOk,
  getCachePartitionDistributionError,
  loadCacheFromStore,
  loadCacheFromStoreErr,
  loadCacheFromStoreOk,
  loadCaches,
  navigateAndRunScanQuery,
  pauseCacheDr,
  pauseCacheDrErr,
  pauseCacheDrOk,
  rebalanceCache,
  rebalanceCacheErr,
  rebalanceCacheOk,
  resumeCacheDr,
  startCacheFst,
  startCacheFstErr,
  startCacheFstOk,
  stopCacheFst,
  stopCacheFstErr,
  stopCacheFstOk,
} from './actions';
import { cachesByClusterId, currentCluster, currentClusterSelector } from './app.selectors';

@Injectable()
export class CacheEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private cacheService: CacheApi,
  ) {}

  loadCachesForCurrentCluster$ = createEffect(() =>
    this.store
      .select(currentCluster)
      .pipe(switchMap((clusterId) => (!clusterId ? EMPTY : [loadCaches({ clusterId })]))),
  );

  loadCaches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCaches),
      pluck('clusterId'),
      mergeMap((clusterId) =>
        !clusterId
          ? EMPTY
          : this.store.select(cachesByClusterId(clusterId)).pipe(
              take(1),
              switchMap((caches) =>
                caches
                  ? EMPTY
                  : this.cacheService.getCaches(clusterId).pipe(map((data) => cachesAction({ data, clusterId }))),
              ),
            ),
      ),
    ),
  );

  rebalanceCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rebalanceCache),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(
        ([{ cacheName }, clusterId]) =>
          (cacheName &&
            clusterId &&
            this.cacheService.rebalanceCache(cacheName, clusterId).pipe(
              map(() =>
                rebalanceCacheOk({
                  notification: `Cache "${cacheName}" rebalance started.`,
                }),
              ),
              catchError((err) =>
                of(
                  rebalanceCacheErr({
                    error: extractError(err, `Failed to rebalance cache`),
                  }),
                ),
              ),
            )) ||
          EMPTY,
      ),
    ),
  );

  enableCacheStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enableCacheStatistics),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(
        ([{ cacheNames }, clusterId]) =>
          (!isEmpty(cacheNames) &&
            clusterId &&
            this.cacheService.enabledCacheStatistics(cacheNames, clusterId).pipe(
              map(() => {
                const cacheCount = cacheNames.length;
                const notification =
                  cacheCount === 1
                    ? `Cache "${cacheNames[0]}" statistics enabled.`
                    : `Cache statistics for ${cacheCount} ${plural('cache', cacheCount)} is enabled`;
                return enableCacheStatisticsOk({ notification });
              }),
              catchError((err) =>
                of(
                  enableCacheStatisticsErr({
                    error: extractError(err, `Failed to enable cache statistics`),
                  }),
                ),
              ),
            )) ||
          EMPTY,
      ),
    ),
  );

  disableCacheStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disableCacheStatistics),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(
        ([{ cacheNames }, clusterId]) =>
          (!isEmpty(cacheNames) &&
            clusterId &&
            this.cacheService.disableCacheStatistics(cacheNames, clusterId).pipe(
              map(() => {
                const cacheCount = cacheNames.length;
                const notification =
                  cacheCount === 1
                    ? `Cache "${cacheNames[0]}" statistics disabled.`
                    : `Cache statistics for ${cacheCount} ${plural('cache', cacheCount)} is disabled.`;
                return disableCacheStatisticsOk({ notification });
              }),
              catchError((err) =>
                of(
                  disableCacheStatisticsErr({
                    error: extractError(err, `Failed to disable cache statistics`),
                  }),
                ),
              ),
            )) ||
          EMPTY,
      ),
    ),
  );

  clearCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearCache),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(
        ([{ cacheName }, clusterId]) =>
          (cacheName &&
            clusterId &&
            this.cacheService.clearCache(cacheName, clusterId).pipe(
              map(() =>
                clearCacheOk({
                  notification: `Cache "${cacheName}" clearing started.`,
                }),
              ),
              catchError((err) =>
                of(
                  clearCacheErr({
                    error: extractError(err, `Failed to clear cache`),
                  }),
                ),
              ),
            )) ||
          EMPTY,
      ),
    ),
  );

  destroyCache$ = createEffect(() =>
    this.actions$.pipe(
      ofType(destroyCache),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(
        ([{ cacheName }, clusterId]) =>
          (cacheName &&
            clusterId &&
            this.cacheService.destroyCache(cacheName, clusterId).pipe(
              map(() =>
                destroyCacheOk({
                  notification: `Cache "${cacheName}" destroyed.`,
                }),
              ),
              catchError((err) =>
                of(
                  destroyCacheErr({
                    error: extractError(err, `Failed to destroy cache`),
                  }),
                ),
              ),
            )) ||
          EMPTY,
      ),
    ),
  );

  pauseCacheDr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pauseCacheDr),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(([action, clusterId]) => {
        if (clusterId !== null) {
          return this.cacheService.pauseDataReplication(action.cacheNames, clusterId).pipe(
            map(() => pauseCacheDrOk()),
            catchError((err) =>
              of(
                pauseCacheDrErr({
                  error: extractError(err, 'Failed to pause data replication'),
                }),
              ),
            ),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  resumeCacheDr$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resumeCacheDr),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(([action, clusterId]) => {
        if (clusterId !== null) {
          return this.cacheService.resumeDataReplication(action.cacheNames, clusterId).pipe(
            map(() => pauseCacheDrOk()),
            catchError((err) =>
              of(
                pauseCacheDrErr({
                  error: extractError(err, 'Failed to resume data replication'),
                }),
              ),
            ),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  startFst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startCacheFst),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(([action, clusterId]) => {
        if (clusterId !== null) {
          return this.cacheService.startFullStateTransfer(action.cacheNames, clusterId).pipe(
            map(() => startCacheFstOk()),
            catchError((err) =>
              of(
                startCacheFstErr({
                  error: extractError(err, 'Failed to start full state transfer'),
                }),
              ),
            ),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  stopFst$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stopCacheFst),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(([action, clusterId]) => {
        if (clusterId !== null) {
          return this.cacheService.stopFullStateTransfer(action.cacheNames, clusterId).pipe(
            map(() => stopCacheFstOk()),
            catchError((err) =>
              of(
                stopCacheFstErr({
                  error: extractError(err, 'Failed to stop full state transfer'),
                }),
              ),
            ),
          );
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  loadCacheFromStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCacheFromStore),
      withLatestFrom(this.store.select(currentCluster)),
      switchMap(([{ cacheName }, clusterId]) =>
        cacheName && clusterId
          ? this.cacheService.loadCacheFromCacheStore(cacheName, clusterId).pipe(
              map(() => loadCacheFromStoreOk({ notification: `Started loading cache "${cacheName}"` })),
              catchError((err) =>
                of(
                  loadCacheFromStoreErr({
                    error: extractError(err, `Error loading cache "${cacheName}"`),
                  }),
                ),
              ),
            )
          : EMPTY,
      ),
    ),
  );

  getCachePartitionDistributionError$ = createEffect(() =>
    interceptActionTaskErrors(this.cacheService, 'getCachePartitions').pipe(
      map((e) =>
        getCachePartitionDistributionError({
          error: extractError(
            (e as ClusterActionResponseError[])[0]?.message,
            $localize`:@@caches__get-pds__fallback-error-message:Failed to get cache partition distribution`,
          ),
        }),
      ),
    ),
  );

  getCachePartitionDistributionLoading$ = createEffect(() => endpointLoading(this.cacheService, 'getCachePartitions'));

  navigateAndRunScanQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateAndRunScanQuery),
      withLatestFrom(this.store.select(currentClusterSelector)),
      switchMap(([{ cacheName }, currentCluster]) =>
        cacheName && currentCluster
          ? // navigate to queries screen
            from(this.router.navigateByUrl(`/clusters/${currentCluster}/sql/queries`)).pipe(
              // run scan query
              map(() => runScanQuery({ cacheName })),
            )
          : EMPTY,
      ),
    ),
  );
}
