/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { deployNebulaClusterOk } from '@app/cluster-management/ngrx/cluster-management.actions';
import { isTruthy } from '@app/common/utils';
import { DialogTutorialsComponent } from '@app/core/header/tutorials/dialog-tutorials/dialog-tutorials.component';
import { TutorialsService } from '@app/core/services';
import { AppState } from '@app/core/types';
import { getProjectName } from '@app/domain/application';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { attachClusterOk } from './actions';
import { clusterList, targetSelector } from './app.selectors';
import { isTutoralComplete } from './tutorial.selectors';

@Injectable()
export class TutorialsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private tutorials: TutorialsService,
  ) {}

  onboardingOnAttachCluster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(attachClusterOk),
        withLatestFrom(
          this.store.select(targetSelector).pipe(filter(isTruthy)),
          this.store.select(isTutoralComplete('welcome-new-user')),
        ),
        switchMap(([, target, isComplete]) => {
          if (isComplete) {
            return EMPTY;
          } else {
            return this.actions$.pipe(
              // Wait until "Add Cluster" dialog is closed, otherwise navigation won't work
              ofType(routerNavigatedAction),
              take(1),
              switchMap(() =>
                this.actions$.pipe(
                  // Wait until Dashboard navigation will work
                  ofType(ROUTER_NAVIGATED),
                  take(1),
                  withLatestFrom(this.store.select(clusterList)),
                  tap(([, clusterList]) => {
                    if (clusterList.length <= 1) {
                      this.matDialog?.open(DialogTutorialsComponent, {
                        data: {
                          title: 'Onboarding tour',
                          description: `Congratulations! 🎉 Would you like to take a quick tour of ${getProjectName(
                            target,
                          )} features?`,
                          dismissButtonLabel: 'No',
                          confirmButtonLabel: 'Yes',
                          tutorial: 'welcome-new-user',
                        },
                        width: '560px',
                        position: { top: '50px' },
                      });
                    }
                  }),
                ),
              ),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  onboardingOnProvisionCluster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deployNebulaClusterOk),
        withLatestFrom(
          this.store.select(targetSelector).pipe(filter(isTruthy)),
          this.store.select(isTutoralComplete('welcome-new-user')),
        ),
        take(1),
        switchMap(([, target, isComplete]) => {
          if (isComplete) {
            return EMPTY;
          } else {
            return this.actions$.pipe(
              ofType(routerNavigatedAction),
              filter((action) => action.payload.event.url.includes('monitoring-dashboard')),
              take(1),
              withLatestFrom(this.store.select(clusterList)),
              map(([, clusterList]) => {
                if (clusterList.length <= 1 && this.tutorials) {
                  this.matDialog?.open(DialogTutorialsComponent, {
                    data: {
                      title: 'Onboarding tour',
                      description: `Congratulations! 🎉 Would you like to take a quick tour of ${getProjectName(
                        target,
                      )} features?`,
                      dismissButtonLabel: 'No',
                      confirmButtonLabel: 'Yes',
                      tutorial: 'welcome-new-user',
                    },
                    width: '560px',
                    position: { top: '50px' },
                  });
                } else {
                  return [];
                }
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );
}
